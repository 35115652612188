<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">业绩统计</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left'  size="large">
                    <el-form-item>
						<el-input  :maxlength="50" style="width:300px;" v-model="searchInfo" placeholder="请输入分销员昵称/手机号" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(searchInfo)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
        <div class="jifen_top"><p>分销积分：<span>{{fenxiaoJf}}</span></p><p>提现积分：<span>{{tixianJf}}</span></p><p>提现金额(元)：<span>{{tixianJe}}</span></p></div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="nickname" label="分销员昵称" ></el-table-column>
				<el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="fenxiaoJf" label="分销积分" ></el-table-column>
				<el-table-column prop="tixianJf" label="提现积分" ></el-table-column>
				<el-table-column prop="tixianJe" label="提现金额（元）" ></el-table-column>
				<el-table-column prop="childCount" label="下级用户数" ></el-table-column>
			</el-table>
		</div>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		performanceList 
	} from "@/api/platform/index";
	export default {
		name: "performanceList",
		data() {
			return {
                searchInfo:'',
				data: [],
                fenxiaoJf:'0.00',          // 分销积分
                tixianJe:'0.00',           // 提现金额
                tixianJf:'0.00',           // 提现积分
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
                let that = this;
				performanceList({
                    searchInfo:that.searchInfo,
					page: that.page,
					size: that.pageSize,
				}).then(res => {
                    if(res.code == 200){
                        that.fenxiaoJf = res.data.fenxiaoJf;
                        that.tixianJe = res.data.tixianJe;
                        that.tixianJf = res.data.tixianJf;
                        that.data = res.data.pageData.records;
                        that.total = res.data.pageData.total;
                        that.currentPage = res.data.pageData.current;
                        that.pageCount = res.data.pageData.pages;
                        if (res.data.pageData.current > res.data.pageData.pages && res.data.pageData.pages != 0) {
                            that.page = res.data.pageData.pages;
                            that.list();
                        }
                    }else{
                        that.$message.error(res.msg);
                    }
				})
			},
            
            //搜索提交
			seachSumbit(){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.jifen_top{
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    display: flex;
    align-content: center;
    justify-items: flex-start;
}
.jifen_top span{
    margin-right: 20px;
    font-weight: bold;
}
</style>